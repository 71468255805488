<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- <vuexy-logo /> -->
          <b-img
            v-bind="mainProps"
            :src="appLogoImage"
            alt="logo"
          />
          <h2 class="brand-text text-primary ml-1">
            Libro Digital
          </h2>
        </b-link>

        <b-card-title class="mb-1 text-center">
          {{ mensajeDeLogin }}
        </b-card-title>
        <b-card-text class="mb-2 text-center">
          Inicia sesión y comienza la aventura.
        </b-card-text>
        <b-alert
          v-if="alert"
          variant="danger"
          show
        >
          <div class="alert-body font-small-2">
            <p>
              <small class="mr-50">{{ alertErrorMessage }}</small>
            </p>
          </div>
        </b-alert>

        <!-- form -->
        <b-form
          class="auth-login-form mt-2"
          @submit.prevent="submit"
        >

          <!-- rut -->
          <b-form-group
            label="Rut"
            label-for="rut"
          >
            <b-form-input
              id="rut"
              v-model="form.rut"
              name="login-rut"
              placeholder="Ej: 12.345.678-K"
              autofocus
              :state="v$.form.rut.$error === true
                ? false
                : null"
              @blur.native="v$.form.rut.$touch"
              @keyup="form.rut = formatRut(form.rut)"
            />
            <b-form-invalid-feedback
              v-if="v$.form.rut.$error"
              id="rutInfo"
              class="text-right"
            >
              <p v-for="error of v$.form.rut.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </b-form-invalid-feedback>
          </b-form-group>

          <!-- contraseña -->
          <b-form-group>
            <div class="d-flex justify-content-between">
              <label for="password">Clave</label>
              <!-- <b-link :to="{name:'auth-forgot-password-v1'}">
                <small>olvidó su contraseña?</small>
              </b-link> -->
            </div>
            <b-input-group
              class="input-group-merge"
              :class="v$.form.password.$error === true ? 'is-invalid':null"
            >
              <b-form-input
                id="password"
                v-model="form.password"
                :type="passwordFieldType"
                class="form-control-merge"
                name="login-password"
                placeholder="********"
                :state="v$.form.password.$error === true
                  ? false
                  : null"
                @blur.native="v$.form.password.$touch"
              />

              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <b-form-invalid-feedback
              v-if="v$.form.password.$error"
              id="passwordInfo"
              class="text-right"
            >
            <p v-for="error of v$.form.password.$errors" :key="error.$uid">
              {{ error.$message }}
            </p>
            </b-form-invalid-feedback>
          </b-form-group>

          <!-- checkbox -->
          <!-- <b-form-group>
            <b-form-checkbox
              id="remember-me"
              v-model="status"
              name="checkbox-1"
            >
              Recordarme
            </b-form-checkbox>
          </b-form-group> -->

          <!-- submit button -->
          <b-button
            variant="primary"
            type="submit"
            block
            :disabled="spinner"
          >
            <b-spinner
              v-if="spinner"
              label="Iniciando Sesión"
              small
            >
            </b-spinner>
            Iniciar Sesión
          </b-button>
        </b-form>

        <div class="divider mt-2">
          <div class="divider-text">
            Softinnova
          </div>
        </div>

        <!-- social button -->
        <!-- <div class="auth-footer-btn d-flex justify-content-center">
          <b-button
            href="javascript:void(0)"
            variant="facebook"
          >
            <feather-icon icon="FacebookIcon" />
          </b-button>
        </div> -->
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>

// ETIQUETAS
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText,
  BInputGroup, BInputGroupAppend, BFormCheckbox, BFormInvalidFeedback, BSpinner,
  BAlert, BImg,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'

// STORE
import store from '@/store/index'
// VUEX
import { mapActions, mapGetters, mapMutations } from 'vuex'

// MIXINS
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { rut } from '@core/mixins/ui/rut'

import { $themeConfig } from '@themeConfig'

// VALIDACIONES
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'


export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormInvalidFeedback,
    BSpinner,
    BAlert,
    BImg,
  },
  mixins: [togglePasswordVisibility, rut],
  data() {
    return {
      // userEmail: '',
      alert: false,
      alertErrorMessage: '',
      mainProps: { width: 56, height: 37 },
      form: [],
      rut: '',
      password: '',
      status: '',
      spinner: false,
      mensajes_login: [
        {
          id: 1,
          mensaje: 'Hola, ¡que bueno verte por acá! 🤗',
        },
        {
          id: 2,
          mensaje: 'Hola, espero que te encuentres muy bien! 👋',
        },
        {
          id: 3,
          mensaje: 'Hola, ¿cómo estás? 👋',
        },
        {
          id: 4,
          mensaje: 'Hola, ¡nos alegrá que estés aquí! 😄',
        },
        {
          id: 5,
          mensaje: 'Hola, ¡te deseamos un lindo día! 😊',
        },
        {
          id: 6,
          mensaje: 'Hola, es un gusto que estés con nosotros! 😊',
        },
      ],
      mensajes_bienvenida_title: [
        {
          id: 1,
          titulo: 'a la educación! 📖',
          icon: 'CoffeeIcon',
          nombre: false,
        },
        {
          id: 2,
          titulo: 'a educar ',
          icon: 'BookIcon',
          nombre: false,
        },
        {
          id: 3,
          titulo: 'al Libro Digital! 📚',
          icon: 'CoffeeIcon',
          nombre: true,
        },
        {
          id: 4,
          titulo: 'a la aventura educativa! 🚀',
          icon: 'CoffeeIcon',
          nombre: false,
        },
        {
          id: 5,
          titulo: 'al futuro educativo 🎓',
          icon: 'BookIcon',
          nombre: false,
        },
        {
          id: 6,
          titulo: 'colega!',
          icon: 'CoffeeIcon',
          nombre: true,
        },
        {
          id: 7,
          titulo: '¡A educar se ha dicho! 🍎',
          icon: 'BookIcon',
          nombre: true,
        },
      ],
      // frases motivacionales y de gratitud
      mensajes_bienvenida_text: [
        {
          id: 1,
          text: 'Aprender es poder! 🧠',
        },
        {
          id: 2,
          text: 'Ahora puedes comenzar a explorar! 🚀',
        },
        {
          id: 3,
          text: 'Gestiona, analiza, enseña! 💫',
        },
        {
          id: 4,
          text: '¡Gracias por tu compromiso con la educación! 👦🏼 👧🏾',
        },
        {
          id: 5,
          text: 'Gracias por tu dedicación! ⭐',
        },
        {
          id: 6,
          text: 'Apreciamos tu espíritu pedagógico! 👦🏻 👧🏿',
        },
        {
          id: 7,
          text: '¡Comienza el viaje educativo! 🚀',
        },
        {
          id: 8,
          text: 'Agradecemos que seas parte de nuestro equipo! ✨',
        },
        {
          id: 9,
          text: '¡Gracias por iluminar nuestro entorno educativo digital! 🌟',
        },
        {
          id: 10,
          text: '¡Sonríe, disfruta tu día! 😁',
        },
        {
          id: 11,
          text: 'Aprender es crecer! 💪🏼',
        },
        {
          id: 12,
          text: '!Gracias por sembrar conocimiento! 👧🏻 👦🏽',
        },
        {
          id: 13,
          text: '¡Gracias por iluminar tu entorno educativo! 👧🏼 👦🏻',
        },
        {
          id: 14,
          text: '!Gracias por educar el futuro! 👦🏾 👧🏼 👦🏻 👧🏽 👦🏿',
        },
      ]
    }
  },

  validations() {
    return {
      form: {
        rut: {
          // $autoDirty: true,
          required: helpers.withMessage('El rut es requerido.', required),
        },
        password: {
          // $autoDirty: true,
          required: helpers.withMessage('La clave es requerida.', required),
        },
      }
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      authErrorMessage: 'auth/authErrorMessage',
      getCursosPersonaRol: 'cursos/getCursosPersonaRol',
    }),
    mensajeDeLogin() {
      const min = 0
      const max = this.mensajes_login.length - 1
      const key = parseInt(Math.floor((Math.random() * (max - min + 1)) + min))
      return this.mensajes_login[key].mensaje
    },
    mensajeDeBienvenidaTitle() {
      const min = 0
      const max = this.mensajes_bienvenida_title.length - 1
      const key = parseInt(Math.floor((Math.random() * (max - min + 1)) + min))
      return this.mensajes_bienvenida_title[key]
    },
    mensajeDeBienvenidaText() {
      const min = 0
      const max = this.mensajes_bienvenida_text.length - 1
      const key = parseInt(Math.floor((Math.random() * (max - min + 1)) + min))
      return this.mensajes_bienvenida_text[key]
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    ...mapActions({
      signIn: 'auth/signIn',
      attempt: 'auth/attempt',
      activaConfiguracionInicial: 'configuracionInicial/activaConfiguracionInicial',
      fetchCursosPersonaRol: 'cursos/fetchCursosPersonaRol',
      desactivaConfiguracionInicial: 'configuracionInicial/desactivaConfiguracionInicial',
    }),
    ...mapMutations('auth', ['SET_ERROR']),
    mensajeBienvenida() {
      const {
        genero,
        nombre
      } = this.user

      let title
      const {
        id,
        titulo,
        icon
      } = this.mensajeDeBienvenidaTitle
        const { text } = this.mensajeDeBienvenidaText
      if (genero === 1) {
        title = `Bienvenido ${titulo}`
        if (id === 2 || id === 6) {
          // 👨‍🏫👨🏻‍🏫👨🏽‍🏫👨🏾‍🏫👨🏿‍🏫
          title = `${title} 👨🏻‍🏫`
        }
      } else if (genero === 2) {
        title = `Bienvenida ${titulo}`
        if (id === 2 || id === 6) {
          // 👩🏻‍🏫👩‍🏫👩🏽‍🏫👩🏾‍🏫👩🏿‍🏫
          title = `${title} 👩🏻‍🏫`
        }
      } else if (genero === 3) {
        title = `Bienvenido (a) ${titulo}`
        if (id === 2 || id === 6) {
          title = `${title} 👩🏻‍🏫 👨🏻‍🏫`
        }
      } else {
        title = `Bienvenido (a) ${titulo}`
        if (id === 2 || id === 8) {
          title = `${title} 👩‍🏫 👨‍🏫`
        }
      }

      const data = {title, text, icon}
      const timeout = 4000
        this.$toast({
          component: ToastificationContent,
          props: {
            title,
            icon,
            variant: 'primary',
            text,
          },
        },
        {
          position: 'top-center',
          timeout,
        })
      // return data
    },
    loginNormal() {
      this.$store.commit('appConfig/UPDATE_LAYOUT_TYPE', 'vertical')
      this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
      this.$store.commit('appConfig/UPDATE_SKIN', 'semi-dark')
      this.desactivaConfiguracionInicial()
      let permisos = [{
        action: 'read',
        subject: 'home',
      }]
      this.user.permisos.forEach(permiso => {
        permisos.push(permiso)
      })
      this.$ability.update(permisos)
      this.$router.replace({
        name: 'inicio',
      }).then(() => {
        this.mensajeBienvenida()

        // const timeout = 4000000
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title,
        //     icon,
        //     variant: 'primary',
        //     text,
        //   },
        // },
        // {
        //   position: 'top-center',
        //   timeout,
        // })
        this.spinner = false
      })
    },
    configuracionInicialDocente() {
      this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
      this.$store.commit('appConfig/UPDATE_LAYOUT_TYPE', 'horizontal')
      this.activaConfiguracionInicial()
      let permisos = [{
        action: 'read',
        subject: 'carga-horaria',
      }]
      this.$ability.update(permisos)
      this.$router.replace({
        name: 'carga-horaria',
      }).then(() => {
        const title = `Hola 👋
${this.user.nombre} ${this.user.primer_apellido} ${this.user.segundo_apellido}`
        const text = `Para utilizar el sistema, primero debes
                       ingresar tu carga horaria.`

        this.$swal({
          title,
          text,
          confirmButtonText: 'Ok, empecemos!',
          // eslint-disable-next-line global-require
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        this.spinner = false
      })
    },
    submit() {
      this.spinner = true
      this.v$.form.$touch()
      if (!this.v$.form.$invalid) {
        // Envía el form a la action signIn
        const rut = this.form.rut.replace('-', '').replace('.', '').replace('.', '')
        const form = {
          rut,
          password: this.form.password
        }

        this.signIn(form).then(response => {
          if (response === undefined && this.authErrorMessage === null) {
            this.fetchCursosPersonaRol(this.user.id_establecimiento).then(() => {
              localStorage.setItem('userData', JSON.stringify(this.user))

              if (this.getCursosPersonaRol.length === 0 && this.user.id_rol === 2) {   // ENTRADA AL SISTEMA POR CONFIGURACION INICIAL
                this.configuracionInicialDocente()
              } else {  // ENTRADA NORMAL AL SISTEMA
                this.loginNormal()
              }
            })
          }
          else if (response.error === 'Usuario incorrecto') {
            this.alert = true
            this.spinner = false
            this.alertErrorMessage = response.error
          }
          // if (this.authErrorMessage === 'Usuario Inactivo') {
          //   this.alert = true
          //   this.alertErrorMessage = 'El usuario asociado al correo electrónico ingresado, se encuentra Inactivo!'
          //   store.commit('auth/SET_ERROR', null)
          // }
        }).catch(() => {
          this.alert = false
          this.spinner = false
          this.alertErrorMessage = ''
          store.auth.commit('SET_ERROR', null)
        })
      } else {
        this.spinner = false
      }
    },
  },
  setup() {
    const { appName, appLogoImage } = $themeConfig.app
    return {
      v$: useVuelidate(),

      // App Name
      appName,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
